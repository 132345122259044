import * as React from "react"
import {IIndexPost, IndexPost} from "../components/posts"
import {graphql} from "gatsby";

interface Node<Type> {
    id: string
    node: Type;
}

export interface IHomePage {
    data: {
        allMarkdownRemark: {
            totalCount: number
            edges: Array<Node<IIndexPost>>
        }
    }

}

export default function HomePage({data}:IHomePage) {
    return (
        <main>
        <div className="container-fluid">
            <div className="hero-unit">
                <h1>Straightup.wtf</h1>
            </div>
            <h4>{data.allMarkdownRemark.totalCount} Posts</h4>
            <ol className="list-group list-group-numbered">
                {data.allMarkdownRemark.edges.map((node) => (
                    <div key={node.id}>
                        <IndexPost {...node.node} />
                    </div>
                ))}


            </ol>

        </div>
        </main>
    )
}
export const query = graphql`
  query {
    allMarkdownRemark {
      totalCount
      edges {
        node {
          id
          ...IndexPostFragment
        }
      }
    }
  }
`