import React from "react"
import { graphql } from "gatsby"

export interface IIndexPost {
    timeToRead: number;
    frontmatter: {
        title: string,
        slug: string,
        date: string
    },
    excerpt: string
}

export function IndexPost(post: IIndexPost) {
    return (
       <li className="list-group-item d-flex justify-content-between align-items-start">

           <div className="ms-2 me-auto">
                    <a href={post.frontmatter.slug}><div className="fw-bold">{post.frontmatter.title}</div></a>

                    <p className="mb-1">{post.excerpt}</p>

                </div>
           <div>
               <small>{post.frontmatter.date}</small>&nbsp;
               <span className="badge bg-primary rounded-pill">{post.timeToRead} min read</span>
           </div>



       </li>

    )
}
export const query = graphql`
  fragment IndexPostFragment on MarkdownRemark {
  frontmatter {
    title
    date(formatString: "MMMM DD, YYYY")
    slug
  }
  excerpt(pruneLength: 500)
  timeToRead
}
`